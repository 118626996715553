// window.bootstrap = require("./bootstrap");
window.moment = require("moment");

import {
    Tab,
    Collapse,
    initMDB,
    Tooltip,
    Modal,
} from "mdb-ui-kit/js/mdb.es.min.js";

import { Chart } from "mdb-ui-kit/js/chart.es.min.js";

initMDB({ Collapse, Tab, Tooltip, Modal, Chart });

//mobile accordion instead of tabs

$("body").on("click", ".accordion-body .dropdownItem", function (event) {
    event.preventDefault();
    var target = $(this).data("tab");

    var targetTabEl = document.querySelector("#" + target + " button");
    var targetTab = new bootstrap.Tab(targetTabEl);

    targetTab.show();

    //change current dropdown and close accordion
    var text = $(this).find("div")[0].innerHTML;
    console.log(text);

    $("#mobileAccordionNav .text").empty();
    $("#mobileAccordionNav .text").append(text);

    // $('#tabAccordion').collapse('hide')
    $("#tabAccordion").find(".collapse").removeClass("show");
    $("#mobileAccordionNav").addClass("collapsed");
});
